// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-beyond-deck-tsx": () => import("./../../../src/pages/beyond-deck.tsx" /* webpackChunkName: "component---src-pages-beyond-deck-tsx" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-bookmarks-js": () => import("./../../../src/pages/bookmarks.js" /* webpackChunkName: "component---src-pages-bookmarks-js" */),
  "component---src-pages-bookshelf-tsx": () => import("./../../../src/pages/bookshelf.tsx" /* webpackChunkName: "component---src-pages-bookshelf-tsx" */),
  "component---src-pages-call-for-founding-engineers-tsx": () => import("./../../../src/pages/call-for-founding-engineers.tsx" /* webpackChunkName: "component---src-pages-call-for-founding-engineers-tsx" */),
  "component---src-pages-call-tsx": () => import("./../../../src/pages/call.tsx" /* webpackChunkName: "component---src-pages-call-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-links-tsx": () => import("./../../../src/pages/links.tsx" /* webpackChunkName: "component---src-pages-links-tsx" */),
  "component---src-pages-people-tsx": () => import("./../../../src/pages/people.tsx" /* webpackChunkName: "component---src-pages-people-tsx" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-projects-peekaboo-js": () => import("./../../../src/pages/projects/peekaboo.js" /* webpackChunkName: "component---src-pages-projects-peekaboo-js" */),
  "component---src-pages-projects-snapcard-js": () => import("./../../../src/pages/projects/snapcard.js" /* webpackChunkName: "component---src-pages-projects-snapcard-js" */),
  "component---src-pages-questions-js": () => import("./../../../src/pages/questions.js" /* webpackChunkName: "component---src-pages-questions-js" */),
  "component---src-pages-quotes-tsx": () => import("./../../../src/pages/quotes.tsx" /* webpackChunkName: "component---src-pages-quotes-tsx" */),
  "component---src-pages-tools-js": () => import("./../../../src/pages/tools.js" /* webpackChunkName: "component---src-pages-tools-js" */),
  "component---src-pages-work-tsx": () => import("./../../../src/pages/work.tsx" /* webpackChunkName: "component---src-pages-work-tsx" */)
}

